import EzComponent       from '@frontend/components/EzComponent';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

class LazyComponent extends EzComponent{

  render(){
    const { children } = this.props;

    return(
      <LazyLoadComponent>
        {children}
      </LazyLoadComponent>
    );
  }
}

export default LazyComponent;
